<template>
  <AModalContent
    class="image-modal"
    @click.prevent="$emit('close')"
  >
    <img
      :src="image"
      alt="image"
    >
  </AModalContent>
</template>
<script>
import { defineComponent } from "vue"
import AModalContent from "../Modal/AModalContent.vue"
export default defineComponent({
    components: { AModalContent },
    props: {
        image: String
    },
    setup() {
        return {}
    }
})
</script>
<style lang="scss">
.image-modal {
    width: 100%;
    height: 100%;
    img {
        object-fit: contain;
        width: 90%;
        max-width: 800px;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>